// #region Global Imports
import * as React from 'react';
import { format, parseISO } from 'date-fns';
import { gql } from '@generated/gql';
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
// #endregion Global Imports

export const DateFragment = gql(/* GraphQL */ `
  fragment DateFragment on NodePost {
    date: fieldDate {
      date
      value
    }
  }
`);

export const Date: React.FC<{ entity?: FragmentType<typeof DateFragment> }> = ({
  entity: _entity,
}) => {
  const entity = getFragmentData(DateFragment, _entity);
  return (
    <span>{format(parseISO(String(entity?.date?.value)), 'd.M.yyyy')}</span>
  );
};
