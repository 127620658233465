// #region Global Imports
import * as React from 'react';
// #endregion Global Imports
// #region Local Imports
import Link from 'next/link';
import type { ImageFragment } from '@components/Paragraphs/ParagraphMedia/Image';
import { Image } from '@components/Paragraphs/ParagraphMedia/Image';
import { gql } from '@generated/gql';
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import styles from './Authors.module.sass';
import type { IProps } from './Authors.d';
// #endregion Local Imports

export const AuthorFragment = gql(/* GraphQL */ `
  fragment AuthorFragment on FieldNodePostFieldAuthors {
    entity {
      id: uuid
      name
      firstName: fieldFirstName
      lastName: fieldLastName
      image: fieldImage {
        entity {
          ... on MediaImage {
            ...ImageFragment
          }
        }
      }
      text: fieldText {
        processed
      }
    }
  }
`);

export const Author: React.FC<IProps.Author> = ({ author: _author }) => {
  const author = getFragmentData(AuthorFragment, _author);
  const entity = author.entity;
  return (
    <div className={styles.Author__root}>
      <Link
        className={styles.Author__img}
        href={`/search?author=${String(entity?.name)}`}
      >
        <Image
          image={entity?.image?.entity as FragmentType<typeof ImageFragment>}
        />
      </Link>
      <div className={styles.Author__txt}>
        <h3 className={styles.Author__name}>{String(entity?.name)}</h3>
        <div className={styles.Author__info}>
          <div
            dangerouslySetInnerHTML={{
              __html: String(entity?.text?.processed),
            }}
          />
        </div>
      </div>
    </div>
  );
};
