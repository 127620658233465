// noinspection DuplicatedCode
// #region Global Imports
import * as React from 'react';
import Link from 'next/link';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import styles from '@components/Taxonomies/Taxonomy.module.sass';
import { gql } from '@generated/gql';
import { getFragmentData } from '@generated/fragment-masking';
import type { IProps } from '../Taxonomies';
// #endregion Local Imports

export const CategoryFragment = gql(/* GraphQL */ `
  fragment CategoryFragment on FieldNodePostFieldCategory {
    id: targetId
    term: entity {
      uuid
      value: entityTranslation(language: $language) {
        id: entityUuid
        name: entityLabel
      }
    }
  }
`);

export const Category: React.FC<IProps.Category> = ({
  category: _category,
  style,
}) => {
  const category = getFragmentData(CategoryFragment, _category);
  const name = category.term?.value?.name ?? '';
  return (
    <span className={styles.Tag__root}>
      <Link
        className={cx(styles.Tag__link, 'Tag__link')}
        href={`/search?category=${String(name)}`}
      >
        <div
          className={cx(
            styles.Tag__inner,
            styles[`Tag--inner-${style ?? 'normal'}`],
          )}
        >
          <div
            className={cx(
              styles.Tag__txt,
              styles[`Tag__txt_${style ?? 'normal'}`],
              {
                'h1-small': true,
              },
            )}
          >
            {name}
          </div>
        </div>
      </Link>
    </span>
  );
};
