// noinspection DuplicatedCode

// #region Global Imports
import * as React from 'react';
// #endregion Global Imports
// #region Local Imports
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import { Tag, TagFragment } from '@components/Taxonomies/Tag';
import { gql } from '@generated/gql';
import type { IProps } from '../Taxonomies';
import styles from '../Taxonomies.module.sass';
// #endregion Local Imports

export const TagsFragment = gql(/* GraphQL */ `
  fragment TagsFragment on NodePost {
    tags: fieldTags {
      id: targetId
      ...TagFragment
    }
  }
`);

export const Tags: React.FunctionComponent<IProps.Tags> = ({
  entity: _entity,
  style,
}) => {
  const entity = getFragmentData(TagsFragment, _entity);
  return (
    <div className={styles.Tags__root}>
      <div className={styles.Tags__list}>
        {entity.tags?.map((_tag) => {
          const tag = getFragmentData(TagFragment, _tag);
          return (
            <div className={styles.Tags__item} key={tag?.id}>
              <Tag
                style={style}
                tag={tag as FragmentType<typeof TagFragment>}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
