// noinspection DuplicatedCode
// #region Global Imports
import * as React from 'react';
import Link from 'next/link';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { gql } from '@generated/gql';
import { getFragmentData } from '@generated/fragment-masking';
import styles from '../Taxonomy.module.sass';
import type { IProps } from '../Taxonomies';
// #endregion Local Imports

export const TagFragment = gql(/* GraphQL */ `
  fragment TagFragment on FieldNodePostFieldTags {
    id: targetId
    term: entity {
      value: entityTranslation(language: $language) {
        id: entityUuid
        name: entityLabel
      }
    }
  }
`);

export const Tag: React.FC<IProps.Tag> = ({ tag: _tag, style }) => {
  const tag = getFragmentData(TagFragment, _tag);
  const name = tag.term?.value?.name ?? '';
  return (
    <span className={styles.Tag__root}>
      <Link
        className={cx(styles.Tag__link, 'Tag__link')}
        href={`/search?tag=${String(name)}`}
      >
        <div
          className={cx(
            styles.Tag__inner,
            styles[`Tag--inner-${style ?? 'normal'}`],
          )}
        >
          <div
            className={cx(
              styles.Tag__txt,
              styles[`Tag__txt_${style ?? 'normal'}`],
              {
                'h1-small': true,
              },
            )}
          >
            {name}
          </div>
        </div>
      </Link>
    </span>
  );
};
