// noinspection DuplicatedCode

// #region Global Imports
import * as React from 'react';
// #endregion Global Imports
// #region Local Imports
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import styles from '@components/Taxonomies/Taxonomies.module.sass';
import type { CategoryFragment } from '@components/Taxonomies/Category';
import { Category } from '@components/Taxonomies/Category';
import { gql } from '@generated/gql';
import type { IProps } from '../Taxonomies';
// #endregion Local Imports

export const CategoriesFragment = gql(/* GraphQL */ `
  fragment CategoriesFragment on NodePost {
    categories: fieldCategory {
      id: targetId
      ...CategoryFragment
    }
  }
`);

export const Categories: React.FunctionComponent<IProps.Categories> = ({
  entity: _entity,
  style,
}) => {
  const entity = getFragmentData(CategoriesFragment, _entity);
  return (
    <div className={styles.Tags__root}>
      <div className={styles.Tags__list}>
        {entity.categories?.map((category) => {
          return (
            <div className={styles.Tags__item} key={category?.id}>
              <Category
                category={category as FragmentType<typeof CategoryFragment>}
                style={style}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
