// noinspection DuplicatedCode

// #region Global Imports
import * as React from 'react';
// #endregion Global Imports
// #region Local Imports
import { gql } from '@generated/gql';
import { getFragmentData } from '@generated/fragment-masking';
import type { IProps } from '../Taxonomies';
// #endregion Local Imports

export const DatelineFragment = gql(/* GraphQL */ `
  fragment DatelineFragment on NodePost {
    dateline: fieldDateline {
      id: targetId
      term: entity {
        uuid
        value: entityTranslation(language: $language) {
          id: entityUuid
          name: entityLabel
        }
      }
    }
  }
`);

export const Dateline: React.FC<IProps.Dateline> = ({ entity: _entity }) => {
  const entity = getFragmentData(DatelineFragment, _entity);
  const lastItem = entity?.dateline?.at(-1);
  return (
    <>
      {entity?.dateline?.map((dateline) => {
        const value = dateline?.term?.value;
        const isLastItem = (dateline === lastItem)
        return <span key={value?.id}>{value?.name}{isLastItem ? null : " / "}</span>;
      })}
    </>
  );
};
