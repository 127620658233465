// #region Global Imports
import * as React from 'react';
import NextImage from 'next/image';
import Skeleton from 'react-loading-skeleton';
import { useRouter } from 'next/router';
// #endregion Global Imports
// #region Local Imports
import { gql } from '@generated/gql';
import { getFragmentData } from '@generated/fragment-masking';
import type { IProps } from '@components/Paragraphs/ParagraphMedia/Image/Image';
import styles from './Image.module.sass';
// #endregion Local Imports

export const ImageFragment = gql(/* GraphQL */ `
  fragment ImageFragment on MediaImage {
    image: fieldMediaImage {
      height
      width
      alt
      mime: entity {
        filemime
      }
      s: derivative(style: DEFAULTS) {
        width
        height
        url
      }
      m: derivative(style: DEFAULTM) {
        width
        url
      }
      l: derivative(style: DEFAULTL) {
        width
        url
      }
      xl: derivative(style: DEFAULTXL) {
        width
        url
      }
      xxl: derivative(style: DEFAULTXXL) {
        width
        url
      }
      xxxl: derivative(style: DEFAULTXXXL) {
        width
        url
      }
      original: entity {
        url
      }
    }
  }
`);

export const Image: React.FunctionComponent<IProps.Image> = ({
  image: _image,
  caption,
  hasLightBox = false,
}) => {
  if (!_image) return <></>;

  const router = useRouter();
  const image = getFragmentData(ImageFragment, _image).image;
  const [loading, setLoading] = React.useState(true);
  const [clicked, setClicked] = React.useState(false);

  // @ts-expect-error
  const { s, m, l, xl, xxl, xxxl, original, height, width, alt } = image;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const sets: { width: string; url: string }[] = [
    s,
    m,
    l,
    xl,
    xxl,
    xxxl,
    original,
  ];

  const sizes = [...new Set(sets.map((set) => set.width))]
    .join(',')
    .replace(/,(?<temp1>[^,]*)$/, '$1');

  return (
    <figure
      className={styles.Image__wrapper}
      onClick={() => {
        setClicked(!clicked);
      }}
    >
      {loading ? (
        <Skeleton
          height="100%"
          style={{
            position: 'absolute',
            zIndex: 'auto',
          }}
          width="100%"
        />
      ) : null}
      {clicked && hasLightBox ? (
        <div className={styles.Image__wrapper_scaled}>
          <div className={styles.Image__inner_scaled}>
            <NextImage
              alt={alt as string}
              className={styles.Image__img}
              height={height as number}
              onLoad={(): void => setLoading(false)}
              sizes={sizes}
              src={original?.url}
              style={{
                maxHeight: '75vh',
                maxWidth: '75vw',
              }}
              width={width as number}
            />
          </div>
        </div>
      ) : null}
      <NextImage
        alt={alt as string}
        className={styles.Image__img}
        height={height as number}
        loading={(router.query.loading as 'eager') || 'lazy'}
        onLoadingComplete={(): void => setLoading(false)}
        priority={router.query.loading === 'eager'}
        sizes={sizes}
        src={original?.url}
        width={width as number}
      />
      {caption?.processed ? (
        <figcaption
          dangerouslySetInnerHTML={{
            __html: caption.processed,
          }}
        />
      ) : null}
    </figure>
  );
};
