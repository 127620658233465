// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { Author, AuthorFragment } from '@components/Authors/Author';
import { gql } from '@generated/gql';
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import styles from './Authors.module.sass';
import type { IProps } from './Authors.d';
// #endregion Local Imports

export const AuthorsFragment = gql(/* GraphQL */ `
  fragment AuthorsFragment on NodePost {
    authors: fieldAuthors {
      ...AuthorFragment
    }
  }
`);

export const Authors: React.FC<IProps.Authors> = ({ entity: _entity }) => {
  const entity = getFragmentData(AuthorsFragment, _entity);
  return (
    <div className={styles.Authors__root}>
      {entity?.authors?.map((_author) => {
        const author = getFragmentData(AuthorFragment, _author);
        return (
          <div className={styles.Authors__item} key={author?.entity?.id}>
            <Author author={author as FragmentType<typeof AuthorFragment>} />
          </div>
        );
      })}
    </div>
  );
};

export const AuthorList: React.FunctionComponent<
  React.PropsWithChildren<IProps.AuthorList>
> = ({ entity: _entity, style, children }) => {
  const entity = getFragmentData(AuthorsFragment, _entity);
  return (
    <span
      className={cx(styles[`AuthorsList__${style}`], styles.AuthorList_root)}
    >
      {entity?.authors?.map((_author, index: number) => {
        const author = getFragmentData(AuthorFragment, _author);
        const firstName = String(author?.entity?.firstName);
        const lastName = String(author?.entity?.lastName);

        let name = author?.entity?.name;
        if ((entity.authors?.length ?? 0) > 3 && style === 'postTeaser') {
          name = `${firstName.trim().substring(0, 1)}. ${lastName}`;
        }

        return (
          <React.Fragment key={author?.entity?.id}>
            {index > 0 && ', '} {name}
          </React.Fragment>
        );
      })}
      {children}
    </span>
  );
};
